import moment from "moment";

export const actionTypes = {
  GET_CEP_SERVICEABLE_PROVINCES_REQUEST: "GET_CEP_SERVICEABLE_PROVINCES_REQUEST",
  GET_CEP_SERVICEABLE_PROVINCES_SUCCESS: "GET_CEP_SERVICEABLE_PROVINCES_SUCCESS",
  GET_CEP_SERVICEABLE_PROVINCES_FAILED: "GET_CEP_SERVICEABLE_PROVINCES_FAILED",

  GET_CEP_SERVICEABLE_CITIES_REQUEST: "GET_CEP_SERVICEABLE_CITIES_REQUEST",
  GET_CEP_SERVICEABLE_CITIES_SUCCESS: "GET_CEP_SERVICEABLE_CITIES_SUCCESS",
  GET_CEP_SERVICEABLE_CITIES_FAILED: "GET_CEP_SERVICEABLE_CITIES_FAILED",

  GET_CEP_SERVICEABLE_BARANGAYS_REQUEST: "GET_CEP_SERVICEABLE_BARANGAYS_REQUEST",
  GET_CEP_SERVICEABLE_BARANGAYS_SUCCESS: "GET_CEP_SERVICEABLE_BARANGAYS_SUCCESS",
  GET_CEP_SERVICEABLE_BARANGAYS_FAILED: "GET_CEP_SERVICEABLE_BARANGAYS_FAILED",

  GET_LOCATION_REQUEST: 'GET_LOCATION_REQUEST',
  GET_LOCATION_SUCCESS: 'GET_LOCATION_SUCCESS',
  GET_LOCATION_FAILED: 'GET_LOCATION_FAILED',

  CREATE_CEP_DRAFT_BOOKING_REQUEST: 'CREATE_CEP_DRAFT_BOOKING_REQUEST',
  CREATE_CEP_DRAFT_BOOKING_SUCCESS: 'CREATE_CEP_DRAFT_BOOKING_SUCCESS',
  CREATE_CEP_DRAFT_BOOKING_FAILED: 'CREATE_CEP_DRAFT_BOOKING_FAILED',

  UPDATE_CEP_DRAFT_BOOKING_REQUEST: 'UPDATE_CEP_DRAFT_BOOKING_REQUEST',
  UPDATE_CEP_DRAFT_BOOKING_SUCCESS: 'UPDATE_CEP_DRAFT_BOOKING_SUCCESS',
  UPDATE_CEP_DRAFT_BOOKING_FAILED: 'UPDATE_CEP_DRAFT_BOOKING_FAILED',

  GET_LOCAL_TIME_REQUEST: 'GET_LOCAL_TIME_REQUEST',
  GET_LOCAL_TIME_SUCCESS: 'GET_LOCAL_TIME_SUCCESS',
  GET_LOCAL_TIME_ERROR: 'GET_LOCAL_TIME_ERROR',

  GET_PORT_CODES_REQUEST: 'GET_PORT_CODES_REQUEST',
  GET_PORT_CODES_SUCCESS: 'GET_PORT_CODES_SUCCESS',
  GET_PORT_CODES_FAILED: 'GET_PORT_CODES_FAILED',

  GET_CEP_PACKAGES: "GET_CEP_PACKAGES",
  GET_CEP_PACKAGES_SUCCESS: "GET_CEP_PACKAGES_SUCCESS",
  GET_CEP_PACKAGES_FAILED: "GET_CEP_PACKAGES_FAILED",

  CREATE_CEP_PACKAGE: "CREATE_CEP_PACKAGE",
  CREATE_CEP_PACKAGE_SUCCESS: "CREATE_CEP_PACKAGE_SUCCESS",
  CREATE_CEP_PACKAGE_FAILED: "CREATE_CEP_PACKAGE_FAILED",

  UPDATE_CEP_PACKAGE: "UPDATE_CEP_PACKAGE",
  UPDATE_CEP_PACKAGE_SUCCESS: "UPDATE_CEP_PACKAGE_SUCCESS",
  UPDATE_CEP_PACKAGE_FAILED: "UPDATE_CEP_PACKAGE_SUCCESS",

  FINALIZE_CEP_BOOKING_REQUEST: 'FINALIZE_CEP_BOOKING_REQUEST',
  FINALIZE_CEP_BOOKING_SUCCESS: 'FINALIZE_CEP_BOOKING_SUCCESS',
  FINALIZE_CEP_BOOKING_FAILED: 'FINALIZE_CEP_BOOKING_FAILED',

  CANCEL_CEP_BOOKING_REQUEST: 'CANCEL_CEP_BOOKING_REQUEST',
  CANCEL_CEP_BOOKING_SUCCESS: 'CANCEL_CEP_BOOKING_SUCCESS',
  CANCEL_CEP_BOOKING_FAILED: 'CANCEL_CEP_BOOKING_FAILED',

  BULK_CEP_UPLOAD_REQUEST: "BULK_CEP_UPLOAD_REQUEST",
  BULK_CEP_UPLOAD_SUCCESS: "BULK_CEP_UPLOAD_SUCCESS",
  BULK_CEP_UPLOAD_FAILED: "BULK_CEP_UPLOAD_FAILED",

  GET_CEP_BOOKING_DETAILS_REQUEST: "GET_CEP_BOOKING_DETAILS_REQUEST",
  GET_CEP_BOOKING_DETAILS_SUCCESS: "GET_CEP_BOOKING_DETAILS_SUCCESS",
  GET_CEP_BOOKING_DETAILS_FAILED: "GET_CEP_BOOKING_DETAILS_FAILED",

  DELETE_CEP_PACKAGES_REQUEST: 'DELETE_CEP_PACKAGES_REQUEST',
  DELETE_CEP_PACKAGES_SUCCESS: 'DELETE_CEP_PACKAGES_SUCCESS',
  DELETE_CEP_PACKAGES_FAILED: 'DELETE_CEP_PACKAGES_FAILED',

  CANCEL_CEP_PACKAGES_REQUEST: 'CANCEL_CEP_PACKAGES_REQUEST',
  CANCEL_CEP_PACKAGES_SUCCESS: 'CANCEL_CEP_PACKAGES_SUCCESS',
  CANCEL_CEP_PACKAGES_FAILED: 'CANCEL_CEP_PACKAGES_FAILED',

  RESET_CEP_BOOKING_DETAILS: "RESET_CEP_BOOKING_DETAILS",
  RESET_CEP_ACTION_TYPE: "RESET_CEP_ACTION_TYPE",
  RESET_ERROR: "RESET_ERROR",

  RESET_CEP_PACKAGES_ACTION_TYPE: "RESET_CEP_PACKAGES_ACTION_TYPE",

  RESET_CEP_BULK_ACTION_TYPE: "RESET_CEP_BULK_ACTION_TYPE",
};

export const actionCreators = {
  getCepProvinces: () => ({ type: actionTypes.GET_CEP_SERVICEABLE_PROVINCES_REQUEST }),
  getCepCities: (id) => ({ type: actionTypes.GET_CEP_SERVICEABLE_CITIES_REQUEST, id }),
  getCepBarangays: (id) => ({ type: actionTypes.GET_CEP_SERVICEABLE_BARANGAYS_REQUEST, id }),
  getPickupLocation: (body) => ({ type: actionTypes.GET_LOCATION_REQUEST, body }),
  getLocalTime: () => ({ type: actionTypes.GET_LOCAL_TIME_REQUEST }),
  getPortCodes: (body) => ({ type: actionTypes.GET_PORT_CODES_REQUEST, body }),
  createCepDraftBooking: (body) => ({ type: actionTypes.CREATE_CEP_DRAFT_BOOKING_REQUEST, body }),
  updateCepDraftBooking: (body) => ({ type: actionTypes.UPDATE_CEP_DRAFT_BOOKING_REQUEST, body }),

  getCepPackages: (id) => ({ type: actionTypes.GET_CEP_PACKAGES, id }),
  createCepPackage: (body) => ({ type: actionTypes.CREATE_CEP_PACKAGE, body }),
  updateCepPackage: (body) => ({ type: actionTypes.UPDATE_CEP_PACKAGE, body }),
  finalizeCepBooking: (body) => ({ type: actionTypes.FINALIZE_CEP_BOOKING_REQUEST, body }),
  cancelCepBooking: (body) => ({ type: actionTypes.CANCEL_CEP_BOOKING_REQUEST, body }),
  getCepBookingDetails: (id) => ({type: actionTypes.GET_CEP_BOOKING_DETAILS_REQUEST, id}),

  resetCepBookingDetails: () => ({ type: actionTypes.RESET_CEP_BOOKING_DETAILS }),
  resetCepBookingActionType: () => ({ type: actionTypes.RESET_CEP_ACTION_TYPE }),
  resetErrorMsg: () => ({ type: actionTypes.RESET_ERROR }),
  cepBulkUpload: (body, bookingNumber) => ({ type: actionTypes.BULK_CEP_UPLOAD_REQUEST, body, bookingNumber }),
  deleteCepPackages:(body) => ({type: actionTypes.DELETE_CEP_PACKAGES_REQUEST, body}),
  cancelCepPackages: (body) => ({type: actionTypes.CANCEL_CEP_PACKAGES_REQUEST, body}),
  resetCepBulkActionType: () => ({
    type: actionTypes.RESET_CEP_BULK_ACTION_TYPE
  }),
  resetCepPackageActionType: () => ({ type: actionTypes.RESET_CEP_PACKAGES_ACTION_TYPE }),
};

export const initialState = {
  actionType: "",
  cepPackageActionType: "",
  isLoading: false,
  cepProvinces: [],
  cepCities: [],
  cepBarangays: [],
  bookingDetails: {
    bookingNumber: '',
    accountId: '',
    pickupLocation: {
      id: 0,
      name: '',
    },
    pickup: {
      name: '',
      contact: '',
      email: '',
      additionalNotes: '',
      location: {
        province: '',
        city: '',
        barangay: '',
        streetUnit: '',
        zipCode: '',
      },
    },
    pickupDate: '',
    status: '',
    isMismatch: false,
  },
  localTime: '',
  portCodes: {
    coverageCode: ''
  },
  isCepBookingFinalized: false,
  isCepPackageFinished: false,
  isCepPackageLoading: true,
  cepPackages: [],
  bulk: {},
  bulkErrorList: {
    totalPackages: 0,
    failedPackages: 0,
    packages: [],
  },
  bulkPackages: [],
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CEP_SERVICEABLE_PROVINCES_REQUEST:
    case actionTypes.GET_CEP_SERVICEABLE_CITIES_REQUEST:
    case actionTypes.GET_CEP_SERVICEABLE_BARANGAYS_REQUEST:
    case actionTypes.GET_LOCATION_REQUEST:
    case actionTypes.CREATE_CEP_DRAFT_BOOKING_REQUEST:
    case actionTypes.UPDATE_CEP_DRAFT_BOOKING_REQUEST:
    case actionTypes.GET_LOCAL_TIME_REQUEST:
    case actionTypes.GET_PORT_CODES_REQUEST:
    case actionTypes.BULK_CEP_UPLOAD_REQUEST:
    case actionTypes.FINALIZE_CEP_BOOKING_REQUEST:
    case actionTypes.CANCEL_CEP_BOOKING_REQUEST:
    case actionTypes.GET_CEP_BOOKING_DETAILS_REQUEST:
    case actionTypes.DELETE_CEP_PACKAGES_REQUEST:   
    case actionTypes.CANCEL_CEP_PACKAGES_REQUEST:  
      return Object.assign({}, state, {
        isLoading: true,
      });

    case actionTypes.GET_CEP_PACKAGES:
    case actionTypes.CREATE_CEP_PACKAGE:
      return Object.assign({}, state, {
        isLoading: true,
        isCepBookingFinalized: false,
        isCepPackageFinished: false,
        isCepPackageLoading: true,
      });

    case actionTypes.UPDATE_CEP_PACKAGE:
      return Object.assign({}, state, {
        isLoading: true,
        isCepBookingFinalized: false,
        isCepPackageFinished: false,
        isCepPackageLoading: true,
      });

    case actionTypes.GET_CEP_SERVICEABLE_PROVINCES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        cepProvinces: action.payload,
        actionType: action.type,
      });

    case actionTypes.BULK_CEP_UPLOAD_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.BULK_CEP_UPLOAD_SUCCESS,
        bulkPackages: action.data ? action.data.successfulPackages : initialState.bulkPackages,
        bulkErrors: action.data ? action.data.bulkErrors : initialState.bulkErrorList,
      });


    case actionTypes.GET_CEP_SERVICEABLE_CITIES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        cepCities: action.payload.cities,
      });

    case actionTypes.GET_CEP_SERVICEABLE_BARANGAYS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        cepBarangays: action.payload.barangays,
      });

    case actionTypes.GET_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
      });

    case actionTypes.CREATE_CEP_DRAFT_BOOKING_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        bookingDetails: action.payload,
      });

    case actionTypes.UPDATE_CEP_DRAFT_BOOKING_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        bookingDetails: action.payload,
      });


    case actionTypes.GET_LOCAL_TIME_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.GET_LOCAL_TIME_SUCCESS,
        localTime: action.payload.formatted
      });

    case actionTypes.GET_PORT_CODES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.GET_PORT_CODES_SUCCESS,
        portCodes: action.payload
      });

    case actionTypes.GET_CEP_PACKAGES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        cepPackageActionType: actionTypes.GET_CEP_PACKAGES_SUCCESS,
        cepPackages: action.payload.packages
      });

      case actionTypes.GET_CEP_BOOKING_DETAILS_SUCCESS:
        return Object.assign({}, state, {
          isLoading: false,
          cepPackageActionType: actionTypes.GET_CEP_BOOKING_DETAILS_SUCCESS,
          bookingDetails: action.payload
        });  

    case actionTypes.CREATE_CEP_PACKAGE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        cepPackageActionType: actionTypes.CREATE_CEP_PACKAGE_SUCCESS,
        cepPackages: action.payload.packages,
      });

    case actionTypes.UPDATE_CEP_PACKAGE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        cepPackageActionType: actionTypes.UPDATE_CEP_PACKAGE_SUCCESS,
        cepPackages: action.payload.packages,
      });

    case actionTypes.FINALIZE_CEP_BOOKING_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.FINALIZE_CEP_BOOKING_SUCCESS,
        bookingDetails: action.payload,
      });

    case actionTypes.CANCEL_CEP_BOOKING_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: actionTypes.CANCEL_CEP_BOOKING_SUCCESS,
        bookingDetails: action.payload,
      });

    case actionTypes.DELETE_CEP_PACKAGES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        cepPackageActionType: actionTypes.DELETE_CEP_PACKAGES_SUCCESS,
        cepPackages: action.payload,
      });

    case actionTypes.CANCEL_CEP_PACKAGES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        cepPackageActionType: actionTypes.CANCEL_CEP_PACKAGES_SUCCESS,
      });  

    case actionTypes.RESET_CEP_PACKAGES_ACTION_TYPE:
      return Object.assign({}, state, {
        cepPackageActionType: initialState.cepPackageActionType,
      });

    case actionTypes.RESET_ERROR:
      return Object.assign({}, state, {
        error: null
      });

    case actionTypes.GET_CEP_SERVICEABLE_PROVINCES_FAILED:
      return Object.assign({}, state, {
        cepProvinces: [],
        cepCities: [],
        cepBarangays: [],
        isLoading: false,
        error: action.error && action.error.message,
        actionType: action.type,
      });
    case actionTypes.GET_CEP_SERVICEABLE_CITIES_FAILED:
      return Object.assign({}, state, {
        cepCities: [],
        cepBarangays: [],
        isLoading: false,
        error: action.error && action.error.message,
        actionType: action.type,
      });
    case actionTypes.GET_CEP_SERVICEABLE_BARANGAYS_FAILED:
      return Object.assign({}, state, {
        cepBarangays: [],
        isLoading: false,
        error: action.error && action.error.message,
      });

    case actionTypes.CREATE_CEP_DRAFT_BOOKING_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        bookingDetails: initialState.bookingDetails,
        error: action.error && action.error.message,
      });

    case actionTypes.UPDATE_CEP_DRAFT_BOOKING_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        bookingDetails: initialState.bookingDetails,
        error: action.error && action.error.message,
      });

    case actionTypes.FINALIZE_CEP_BOOKING_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        bookingDetails: initialState.bookingDetails,
        error: action.error && action.error.message,
      });

    case actionTypes.CANCEL_CEP_BOOKING_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        actionType: action.type,
        bookingDetails: initialState.bookingDetails,
        error: action.error && action.error.message,
      });

    case actionTypes.DELETE_CEP_PACKAGES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        cepPackageActionType: action.type,
        error: action.error && action.error.message,
      });

    case actionTypes.CANCEL_CEP_PACKAGES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        cepPackageActionType: action.type,
        error: action.error && action.error.message,
      });   

    case actionTypes.GET_LOCAL_TIME_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        localTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      });

    case actionTypes.GET_LOCATION_FAILED:
      return Object.assign({}, state, {
        // cepProvinces: [],
        // cepCities: [],
        // cepBarangays: [],
        isLoading: false,
        error: action.error && action.error.message,
      });

    case actionTypes.GET_PORT_CODES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        portCodes: initialState.portCodes,
        actionType: action.type,
        error: action.error && action.error.message,
      });


    case actionTypes.GET_CEP_PACKAGES_FAILED:
    case actionTypes.CREATE_CEP_PACKAGE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        isCepPackageLoading: false,
        errors: action.error.message,
        cepPackages: initialState.cepPackages,
        cepPackageActionType: action.type
      });

      case actionTypes.GET_CEP_BOOKING_DETAILS_FAILED:
        return Object.assign({}, state, {
          isLoading: false,
          isCepPackageLoading: false,
          errors: action.error.message,
          bookingDetails: initialState.bookingDetails,
          cepPackageActionType: initialState.cepPackageActionType
        });  

    case actionTypes.UPDATE_CEP_PACKAGE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        isCepPackageLoading: false,
        errors: action.error.message,
        cepPackageActionType: initialState.cepPackageActionType
      });

    case actionTypes.RESET_CEP_BOOKING_DETAILS:
      return Object.assign({}, state, {
        bookingDetails: initialState.bookingDetails,
      })

    case actionTypes.RESET_CEP_BULK_ACTION_TYPE:
      return Object.assign({}, state, {
        actionType: "",
      });

    case actionTypes.RESET_CEP_ACTION_TYPE:
      return Object.assign({}, state, {
        actionType: '',
      });

    case actionTypes.BULK_CEP_UPLOAD_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        bulk: {},
        errors: action.error.message,
        actionType: actionTypes.BULK_CEP_UPLOAD_FAILED
      });

    default:
      return state;
  }
};
